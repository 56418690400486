.add-user-form{
    /* border: 1px solid red; */
}

.form-header{
    /* border: 2px solid blue; */
    background-color: #03A9F4;
    color: white;
    padding: 40px;
    text-align: center;
    font-size: large;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}