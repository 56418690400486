.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.back {
    width: fit-content;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
}
.back:hover {
    background-color: lightgray;
}
.wrapper {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 90%;
}
.wrapper > h3 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 0 15px;
    font-size: 1.3rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
}

.wrapper form {
    padding: 0 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 0.5rem 2rem;
    align-items: baseline;
}

.wrapper form h3 {
    font-size: 1.1rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
    grid-column: 1/-1;
}
.form-control {
    display: grid;
    gap: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
}
.form-control label {
    font-family: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.form-control input,
.form-control select {
    display: block;
    width: 93%;
    height: 1.6rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    outline: none;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}
.form-control input:focus,
.form-control select:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);

    border: 1px solid rgba(81, 203, 238, 1);
}
.form-control span {
    font-family: inherit;
    color: red;
}
.form-control input:disabled {
    background-color: lightgray;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-control select {
    height: 2.4rem;
    width: 100%;
}
/* Firefox */
input[type="number"] {
    appearance: textfield;
}
.wrapper form > button,
.skills button {
    border: 1px solid #0077b6;
    color: #212529;
    background-color: #fff;
    display: inline-block;
    font-weight: 400;
    color: #0077b6;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    padding: 0.375rem 0.75rem;
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: fit-content;
    height: fit-content;
    grid-column: 1/-1;
    justify-self: center;
}
.wrapper form button:disabled {
    background-color: white;
    border: none;
    width: 6rem;
    display: grid;
    align-items: center;
    justify-items: center;
}
.skill-Wrapper {
    grid-column: 1/-1;
    display: grid;
    gap: 1rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin-top: 1rem;
}

.skill-Wrapper header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
}
.skill-Wrapper header button {
    color: white;
    border: none;
    /* background-color: inherit; */
    background-color: #0077b6;
    padding: 0.2rem;
    cursor: pointer;
    font-weight: bolder;
}
.skill-Wrapper > button,
.qualification button {
    margin-top: 0.5rem;
    background-color: green;
    color: white;
    border: none;
    padding: 0.4rem;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
}
.skills {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0;
}
.form-control-select,
.last div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.form-control-select div {
    display: grid;
    gap: 0.5rem;
    flex-grow: 2;
}
.skills button {
    grid-column: unset;
    align-self: self-end;
}
.qualification {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    gap: 0.5rem;
}
.qualification .form-control:last-child,
.skills .form-control:last-child {
    position: relative;
}
.qualification .form-control:last-child button,
.skills .form-control:last-child button {
    background-color: red;
    padding: 0;
    font-size: 1rem;
    height: 2.3rem;
    border: none;
    color: white;
    right: 0;
    width: fit-content;
    padding: 0 1rem;
    top: 0;
}
.skills .form-control:last-child button {
    right: 10px;
}
.qualification input {
    width: unset;
    padding: 0.5rem 0;
    text-indent: 0.3rem;
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2.3rem;
}
.add-item h5 {
    font-size: 1rem;
    margin: 0;
    padding: 0;

    font-family: "Source Sans Pro", sans-serif;
}
.add-item p {
    /* color: #9e9e9e; */
    color: grey;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    font-family: "Roboto", sans-serif;
}
.file-upload-wrapper {
    border: 1px solid #ccc;

    grid-column: 1/-1;
}
.file-upload-wrapper header {
    padding: 0.3rem;
    border-bottom: 1px solid #ccc;
}
.upload-image {
    padding: 1rem 0;
    width: 100%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.file-images-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    grid-column: 1/-1;
}
.file-images-wrapper img {
    /* width: 100px; */
    height: 80px;
}
.image {
    position: relative;
}
.image button {
    right: 0;
    border: none;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}
.icon {
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
}
.qualification select {
    height: 100%;
    padding: 0.67rem 0;
}
@media screen and (max-width: 700px) {
    .Container {
        padding: 0.8rem;
    }
    .wrapper {
        width: 90%;
    }
    .wrapper form {
        grid-template-columns: 1fr;
    }
    .form-control input {
        width: 90%;
    }
}


.preview-wrapper{
    /* border: 1px solid red; */
}

/* .preview-container{
    border: 1px solid rgb(201, 201, 201);
    width: fit-content;
} */

.preview-container {
    position: relative;
    display: inline-block;
    border: 1px solid rgb(201, 201, 201);
  }
  
  .remove-button {
    position: absolute;
    top: 0;
    right: 0;
    /* left: 0; */
    background-color: rgb(255, 255, 255);
    border: none;
    padding: 0;
    /* margin: 0; */
    /* margin-top: 1px; */
    /* margin-left: 2px; */
    cursor: pointer;
    color: red;
    font-size: 24px;
  }

  .img-main-url{
    /* border: 1px solid red !important; */
  }
  
  .url-img{
    text-align: center !important;
    /* border: 1px solid red; */
  }

  .span-guideurl{
    /* border: 1px solid red !important; */
    /* margin-bottom: 20px; */
    position: absolute;
    margin-top: 23px;
  }

  .span-guideurl:hover{
    color: #0077b6;
    /* text-decoration: underline; */
  }