/* ProductPrice.css */

/* Modal container */
.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Modal title */
  .modal-container h6 {
    margin-bottom: 16px;
  }

  .product-typo{
    /* border: 1px solid red; */
    /* margin: 10px; */
    text-align: center;
    padding: 10px;
    color: #0077B6;
  }

  .prod-typo-textfield{
    /* border: 1px solid green !important; */
    width: 100%;
    /* margin-left: 20px; */
  }
  
  .prod-typo-butn{
    /* border: 1px solid red; */
    text-align: center;
    /* margin: 10px; */
    padding: 10px;
  }

  .prod-typo-butn-new{
    /* border: 1px solid red; */
    text-align: center;
    /* margin: 10px; */
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .close-button {
    font-size: 1rem;
    color: #666;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
  
  

  /* Text field for product price unit */
  .modal-container .MuiTextField-root {
    margin-bottom: 16px;
    width: 100%;
  }
  
  /* Update button */
  .modal-container .MuiButton-root {
    width: 100%;
  }
  
  .plan-update-prod-btn{
    border: none;
    background: none;
    font-size: 20px;
    padding: 10px;
    color: #0077B6;
    cursor: pointer;
  }

  .plan-delete-prod-btn{
    border: none;
    background: none;
    font-size: 20px;
    padding: 10px;
    color: red;
    cursor: pointer;
  }