body{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

table{
  /* font-family: 'Noto Sans Display', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Roboto Slab', serif; */
}

.active {
  padding: 15px;
  cursor: pointer;
  background-color: white;
  border: none;
  }
  .inactive {
  padding: 15px;
  cursor: pointer;
  background-color: white;
  border: none;
  }
  
  button.active {
  background-color: #F9F9F9;
  border: 1px solid rgb(216, 214, 214);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: black;
  }

  .main-data-tab{
    height: 100vh;
    background-color: #EBF2FA;
  }

 .users-main{
  border: 1px solid #EBF2FA;
  background-color: #EBF2FA;
 }

 .search-main{
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 4rem;
 }

 .add-users-btn{
  border: 1px solid #dddedf;
  /* border: 1px solid red; */
  background-color: white;
  margin: 30px;
  cursor: pointer;
  width: fit-content;
 }

 .search-users-btn{
  /* border: 1px solid #dddedf; */
  /* border: 1px solid red; */
  border: 1px solid #dddedf;
  background-color: white;
  margin: 30px;
  cursor: pointer;
  width: fit-content;
 }


 .search-users-btn p{
  /* border: 1px solid red; */
  position: absolute;
  margin-top: 11px;
  font-size: 18px;
  color: gray;
  /* color: #1E87DB; */
  color: #5DA3C6;
  margin-left: 5px;
 }

 .search-users-btn input{
  /* border: 1px solid green; */
  text-indent: 20px;
  border: none;
  color: #1E87DB;
 }

 .search-users-btn input::placeholder{
  color: #1E87DB;
  color: rgb(192, 189, 189);
 }

 .add-user{
  background-color: white;
  color: #1E87DB;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(216, 214, 214); */
  border: none;
  /* margin-bottom: 20px; */
  cursor: pointer;
  padding-right: 10px;
  margin-top: 5px;
  /* padding: 5px; */
 }

 .created-add-users-btn{
  margin: 30px;
 }

 .add-plus{
  font-size: 20px;
  margin-right: 8px;
  margin-bottom: 7px;
  margin-top: 5px;
  cursor: pointer;
 }

 .users-div{
  border: 1px solid white;
  margin-bottom: 10px;
 }

 .users-sub-one{
  /* border: 1px solid white; */
  /* border: 1px solid red; */
  margin: 30px;
  background-color: white;
  border-radius: 5px;
 }

 .users-sub-two{
  margin: 20px;
  background-color: white;
  border-radius: 5px;
 }

 .Add-users-sub-one{
  /* border: 1px solid white; */
  /* border: 1px solid red; */
  margin: 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
 }

 .Add-users-sub-two{
  margin: 20px;
  background-color: white;
  border-radius: 5px;
 }

 .users-heading-one{
     align-items: center;
    color: #0077b6;
    display: flex;
    font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
    font-size: 20px;
    font-weight: 500;
    gap: 1rem;
    text-transform: capitalize;
    border-bottom: 1px solid rgb(234, 233, 233);
 }

 .users-heading-two{
  border-bottom: 1px solid rgb(216, 214, 214);
  /* border: 1px solid red; */
 }

 .users-heading-two button{
  font-size: 15px;
  font-weight: bold;
  color: #515151;
 }

 .users-table-data{
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
 }
  
  .account-btn{
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  }

  .account-btn.status-active-btn{
  background-color: green; 
  color: white;
  width: 80px;
  }

  .account-btn.status-inactive-btn{
  background-color: red; 
  color: white;
  width: 80px;
  }

  .navlink{
  text-decoration: none;
  color: gray;
  }

  /* STYLING FOR TABLE  */

.Table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  /* border: 1px solid red; */
  margin-bottom: 20px;
}

.Table th {
  /* border: 1px solid #ddd; */
  padding: 8px;
  text-align: center;
  background-color: #1E87DB;
  color: white;
  /* width: 100%; */
  font-weight: 500;
}

.Table tr{
  border: 1px solid #ddd;
}

.Table td{
  /* border: 1px solid #ddd; */
  color: gray;
  text-align: center;
  padding: 8px;
}

.navlink {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.Table tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
  /* border: 2px solid red; */
}

.supplier-modal-content{
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.product-close-btn{
  background: #88919b; 
  color: #fff;
  padding: 5px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.reject-status-btn{
  background: #e03d5e;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 70px;
}

.approve-status-btn{
  background: #36ce67;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 70px;
}

.close{
  /* border: 1px solid red; */
  margin-top: 18.5px;
  font-size: 35px;
  padding: 10px;
  position: absolute;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 418px) {
  .toggle-password-btn {
    position: absolute;
    /* top: 50%; */
    /* margin-top: 40px !important;  */
    margin-right: 30px !important;
    right: 10px;
    transform: translateY(-660%) !important;
    background: none;
    border: none;
    cursor: pointer;
    color: gray;
  }
}

/* Responsive Styling */
@media only screen and (max-width: 600px) {
  .modal-content {
    margin-left: 70px;
  }
  .modal-content{
    display: block !important;
    width: fit-content;
  }
  .comment-box{
    margin-top: 10px !important;
    resize: none !important;
    /* border: 1px solid red; */
  }
  
  .comm{
    margin-top: 10px !important;
  }

  .my-modal-content {
    /* border: 1px solid red; */
    width: 50%;
    margin-left: 65px;
  }

  .email-text{
    font-size: 12px;
  }

  .inside-sel-para p {
    margin: 8px 0;
    text-align: left;
    /* border: 1px solid red; */
    width: 200px;
    font-size: small;
  }

  .main-pro-cont{
    /* border: 1px solid white; */
    margin-left: 70px;
  }
  .created-icons{
   display: none;
  }
  .textfield-icons-create{
   display: none;
  }
  .inpt-for-addsub{
    /* border: 1px solid blue !important; */
    margin-top: 20px;
    text-align: left !important;
  }

  .users-sub-one{
    border: 1px solid white;
    margin: 4px;
    background-color: white;
    border-radius: 5px;
   }

   .subscription-card div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid red; */
  }
  
  .status-renew{
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
  }

  
  .toggle-password-btn {
    position: absolute;
    /* top: 50%; */
    margin-top: 53px !important; 
    margin-right: 30px !important;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: gray;
  }

  .renew-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 50% !important;
    text-align: center;
    margin-left: 70px;
    /* border: 2px solid red; */
  }
  .renew-button-confirm,
.renew-button-cancel {
  padding: 10px 20px;
  margin-top: 10px !important;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
}

@media only screen and (max-width: 800px) {
  .user-inside-selected {
    display: flex;
    flex-direction: column;
    text-align: center;
    /* border: 1px solid red; */
  }

  .inside-sel-img{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .inside-sel-para {
    border: none !important;
    text-indent: 0px !important;
  }

  .modal-content-password{
    /* background-color: #ffffff; */
      /* border: 1px solid #ddd; */
      /* border: 1px solid red; */
      border-radius: 8px;
      /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
      padding: 5px !important;
      position: relative;
      /* width: 250px !important; */
      margin-left: 70px;
  }

  .input-pass-div{
    /* border: 10px solid rgb(200, 229, 247); */
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;
    /* border: 2px solid red; */
    width: 100% !important;
  }
  .pass-change-input{
    width: 70% !important;
    padding: 10px 30px;
    /* margin-bottom: 15px; */
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .my-modal-content-one{
    /* border: 2px solid red !important */
    width: 80% !important;
    background-color: white;
    text-align: center;
    margin-left: 40px !important;
  }

  .my-label-two {
    margin-bottom: 10px;
    text-align: left;
    margin-left: 0px !important;
    text-indent: 20px;
    /* border: 1px solid rgb(255, 11, 11) !important; */
    background-color: #0056b3;
    width: 100% !important;
    border-radius: 4px;
  }

  .my-label-two-main {
    margin-bottom: 10px;
    text-align: center;
    margin-left: 0px !important;
    text-indent: 0px !important;
    /* border: 1px solid rgb(231, 231, 231); */
    background-color: #ffffff;
    width: 90% !important;
    border-radius: 4px;
    /* border: 1px solid red; */
  }

  .my-input-head-one{
    /* border: 1px solid green !important; */
    /* padding: 5px; */
    color: #007BFF;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    border: none;
    width: 80% !important;
  }

  .cont-maintitle-icon{
    display: none !important;
  }

  .cont-title-icon{
    /* border: 1px solid red; */
    position: relative !important;
    margin-left: 50px !important;
  }

  .my-input-terms {
    width: 80% !important; 
    /* padding: 8px; */
    /* margin-bottom: 10px; */
    /* border: none; */
    border-radius: 4px;
    margin-left: 0px !important;
    margin-right: 10px;
    /* border: 2px solid rgb(253, 0, 0); */
    text-align: left;
    margin: 5px;
  }

  .my-input-one {
    width: 80% !important;
    /* padding: 8px; */
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid rgb(231, 231, 231);
    text-align: left;
  }
 
}

.each-product-name{
  font-size: 25px;
  font-weight: 600;
  text-transform: capitalize;
}

.each-product-details{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 12px;
}

.adduser-modal{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Adjust the styling for the modal-content */
.supplier-modal-content{
  max-width: 400px; 
  margin: 0 auto;
  padding: 8px;
}

.supplier-modal-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.supplier-modal-content th {
  text-align: left;
  padding: 8px;
}

.supplier-modal-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.supplier-modal-content li {
  margin-bottom: 5px;
  font-size: 10px;
}

.supplier-btn-close{
  padding: 8px;
  background: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.view-more-btn{
    cursor: pointer;
    background-color: white;
    border: none;
    text-decoration: underline;
    color: gray;
}

.view-more-btn:hover{
  background-color: #1E87DB;
  color: white;
}

/* ---------------------------styling for react hook form----------------------- */

/* Styles for the form container */

.main-form-div{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.sub-form-div{
  /* border: 1px solid green; */
}

.form-design{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-container {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  /* border: 1px solid red; */
  background-color: #EBF2FA;
  height: 100vh;
}

.form-label {
  display: block;
  margin-bottom: 8px;
}

.error-message {
  color: red;
  margin-top: 4px;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-indent: 40px;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  transform: translateY(50%);
  margin-left: 20px;
  color: #1E87DB;
}

.users-main-hidden {
  display: none;
}

.user-selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.user-inside-selected {
  display: flex;
  text-align: center;
  /* border: 1px solid red; */
  width: 100%;
  justify-content: space-between;
}

.selected-each-back-btn{
    padding: 10px 15px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px;
}

.selected-each-back-btn:hover {
  background-color: #2980b9;
}
.inside-sel-img{
  /* border: 1px solid green; */
  display: flex;
  text-align: left;
  width: 50%;
}

.selected-p-disp{
  display: flex;
  /* border: 1px solid black; */
  justify-content: flex-start;
  gap: 10px;
}

.selected-p-design{
  color: #4473a5;
  font-size: 15px;
  /* margin-top: 4px; */
}

.user-pass-change-btn-container {
  display: flex;
  align-items: center;
}

.lock-icon {
  margin-right: 8px; 
  font-size: 12px;
  text-indent: 10px;
  font-weight: bold;
  margin-top: 2px;
}


.inside-sel-img img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  /* border-radius: 50%;  */
  border: 2px solid #ddd;
}

.inside-sel-para {
  border-left: 1px solid rgb(223, 223, 223);
  width: 50%;
  /* text-indent: 10px; */
}

.inside-sel-para p {
  /* margin: 8px 0; */
  padding: 5px;
  text-align: start;
  /* border: 1px solid green; */
}

.inside-sel-para p span.label {
  font-weight: bold;
  margin-right: 5px;
}


/* Additional styling for the form-like appearance (optional) */
.user-selected {
  padding: 10px;
  margin: 10px;
}

.email-text{
  color: #0056b3;
}

.main-back-btn {
  background-color: #3498db;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
}

.main-back-btn:hover {
  background-color: #2980b9;
}

.modal-overlay{
  display: flex;
}

.modal-content{
  /* display: flex; */
  width: fit-content;
}

.head-modal-mui{
  /* border: 1px solid red; */
  font-weight: 600 !important;
  margin-left: 5px !important;
}

.image-container {
  flex-shrink: 0; /* Prevent the image container from shrinking */
  margin-right: 10px; 
}

.content-container {
  flex-grow: 1; /* Allow the content container to grow and take available space */
}

.comment-box-container{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.comment-box{
  border: 1px solid rgb(193, 191, 191);
  margin-top: 100px;
  border-radius: 5px;
  height: 5rem;
  resize: none;
  /* border: 1px solid red; */
}

.comm{
  margin-top: 96px;
  background: #3a8ac0;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.status-pending-btn{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-around;
}

@keyframes waterFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.status-tag {
  position: absolute;
  top: 0;
  margin-left: 50px;
  padding: 3px;
  min-width: 70px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
  background-size: 200% auto;
  background-image: linear-gradient(to right, rgb(42, 187, 249), rgb(255, 255, 255)); /* Initial gradient color */
  animation: waterFlow 4s infinite ease-in-out; /* Adjust the duration and easing as needed */
  color: rgb(255, 255, 255);
}

.approved-tag {
  color: white;
  font-weight: 400;
  background-image: linear-gradient(to right, rgb(112, 194, 112), rgb(151, 233, 247));
}

.rejected-tag {
  color: white;
  font-weight: 400;
  background-image: linear-gradient(to right, rgb(245, 172, 172), rgb(236, 92, 149));
}

.pending-tag {
  color: white;
  font-weight: 400;
  background-image: linear-gradient(to right, rgb(136, 135, 135), rgb(219, 171, 234));
}

.product-image{
  /* border: 1px solid red; */
  height: 15rem;
  width: 200px;
}

/* Modal Overlay */
.my-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.my-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Form Styles */
.my-form {
  display: flex;
  flex-direction: column;
}

.my-form-one{
  /* border: 1px solid red; */
}

.my-change-title{
  /* border: 1px solid red; */
  padding: 5px;
  color: #007BFF;
  font-size: 25px;
  font-weight: 600;
}

.my-modal-content-one{
  /* border: 2px solid green; */
  width: 60%;
  background-color: white;
  text-align: center;
}

.my-input-one {
  width: 90%;
  /* padding: 8px; */
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(231, 231, 231);
  text-align: left;
}

.my-label-one {
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
  text-indent: 10px;
}

.my-label-two {
  margin-bottom: 10px;
  text-align: left;
  margin-left: 20px;
  text-indent: 20px;
  border: 1px solid rgb(231, 231, 231);
  background-color: #0056b3;
  width: 91%;
  border-radius: 4px;
}

.my-label-two-main {
  margin-bottom: 10px;
  text-align: center;
  margin-left: 20px;
  text-indent: 20px;
  /* border: 1px solid rgb(231, 231, 231); */
  background-color: #ffffff;
  width: 91%;
  border-radius: 4px;
}

.my-label-three {
  margin-bottom: 10px;
  text-align: left;
  margin-left: 20px;
  text-indent: 20px;
  /* border: 1px solid rgb(231, 231, 231); */
  width: 91%;
  border-radius: 4px;
}

.my-label-three ul{
  /* border: 1px solid red; */
  /* margin: 20px; */
  /* width: 100%; */
}

.my-label-three ul li {
  /* border: 2px solid red; */
  display: flex;
  color: white;
}

.remove-term-btn{
  /* border: 1px solid red; */
  background-color: transparent;
  font-size: 20px;
  /* color: rgb(226, 134, 134); */
  color: white;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}

.my-input-head-one{
  /* border: 1px solid red; */
  /* padding: 5px; */
  color: #007BFF;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  border: none;
  width: 90%;
}

.subscription-cont-change{
  /* border: 1px solid red; */
  border: none;
  padding: 5px;
  background-color: transparent;
  color: #0056b3;
  cursor: pointer;
  font-size: 15px;
}

.cont-title-icon{
  color: white;
  font-size: 16px;
  margin-top: 15px !important;
  /* border: 1px solid red; */
  position: absolute;
  /* margin-left: 10px; */
  margin-right: 20px !important;
  cursor: pointer;
}

.cont-maintitle-icon{
  color: black;
  font-size: 15px;
  margin-top: 14.3px !important;
  /* border: 1px solid red; */
  position: absolute;
  margin-left: 20px;
}

.cont-maintitle-icon-two{
  color: white;
  font-size: 15px;
  margin-top: 15px !important;
  /* border: 1px solid red; */
  position: absolute;
  margin-left: 5px;
}

.subscription-cont-change:hover{
  color: #007BFF;
  text-decoration: underline;
}

.main-title-data-sub{
  /* border: 1px solid red; */
  width: 90%;
  padding: 4px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 10px;
  border: 1px solid rgb(231, 231, 231);
  background-color: #dae7f6;
}

.my-change-subtitle{
  /* border: 1px solid red; */
  /* padding: 5px; */
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  width: 82%;
  /* margin-left: 20px; */
  border: none;
  margin: 5px;
  text-indent: 20px;
  background-color: #0056b3;
}

.my-save{
  /* border: 1px solid green; */
  padding: 10px;
  display: flex;
  justify-content: center;
}

.my-label {
  margin-bottom: 10px;
  text-align: left;
  text-indent: -8px;
}

.my-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: -10px;
  /* border: 1px solid red; */
}

.my-input-terms {
  width: 90%;
  /* padding: 8px; */
  /* margin-bottom: 10px; */
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  /* border: 1px solid rgb(231, 231, 231); */
  text-align: left;
  margin: 5px;
}

.my-button {
  background: #007bff; 
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.my-button:hover {
  background: #0056b3; 
}

.my-main-close-btn{
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
}

.myclose-btn{
  background: red;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  /* border: 1px solid red; */
}

.plan-update-btn{
  border: none;
  background: none;
  font-size: 20px;
  padding: 10px;
  color: #0077B6;
  cursor: pointer;
}

.plan-delete-btn{
  border: none;
  background: none;
  font-size: 20px;
  padding: 10px;
  color: red;
  cursor: pointer;
}

.inactive-button {
  background-color: #ddd; 
  color: #888;
  cursor: not-allowed; 
}

.skel-sub-one{
  /* border: 2px solid red; */
  margin-left: 20px;
}

/* Switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 30px; 
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 7.5px;
}

.slider.round {
  border-radius: 7.5px; 
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px; 
  width: 11px;
  left: 2px; 
  bottom: 2px; 
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #66B6B8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px); 
}

.switch-label {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.switch:hover .block-user-text {
  opacity: 1;
  visibility: visible;
}

.block-user-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 1%;
  transform: translateX(-50%);
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, visibility 0.3s;
}

.block-text-green {
  background-color: #FF5252;
  color: #fff;
}

.block-text-red {
  background-color: #4CAF50;
  color: #fff;
}

.users-sub-two {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  /* overflow-wrap: anywhere; */
}

.users-new-div {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.users-div-main {
  display: flex;
  align-items: center;
  /* margin-left: 30px; */
  /* border: 1px solid blue; */
}

.prod-div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.left-side-image {
  width: 50px;
  height: auto;
  margin-right: 10px;
  /* border: 1px solid red; */
  background: #F1F2E6;
  padding: 5px;
}

.left-side-image-main {
  width: auto;
  height: 60px;
  padding: 10px;
  /* margin-right: 10px; */
  /* border: 1px solid red; */
  /* padding: 10px; */
  position: relative;
}

.content-container {
  flex-grow: 1;
  /* border: 1px solid red; */
  margin: 10px;
}

.users-two {
  /* margin-top: 60px;  */
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid rgb(219, 214, 214); */
  /* border: 1px solid red; */
}

.eachorder-heading-one {
  margin-bottom: 10px; 
}

.each-heading-one{
  font-size: 15px;
  font-weight: 500;
  /* border: 1px solid red; */
  margin-right: 30px;
}

.each-heading-two{
  font-size: 10px;
  font-weight: 500;
  color: gray;
}

.users-two-label {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.io-person{
  font-size: 15px;
  color: #007BFF;
}

.io-rupee{
  font-size: 10px;
  margin-bottom: 1px;
  color: gold;
}

.io-total{
  font-size: 10px;
  color: gold;
  margin-top: 3px;
}

.io-last{
  font-size: 10px;
  margin-top: 3px;
}

.delivery-data{
  display: flex;
}

.users-two-label svg {
  margin-right: 5px;
  color: #007BFF; 
}

.users-two h4 {
  margin-bottom: 15px; 
}

.prod-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; 
  border-bottom: 1px solid rgb(203, 200, 200);
  margin-right: 10px;
}

.prod-div {
  display: flex;
  align-items: center;
}

.user-prod-style {
  display: flex;
  flex-direction: column;
}

.delivery-data {
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
}

.io-person {
  margin-right: 10px; 
  height: 30px;
}

.left-side-image {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.eachorder-heading-one {
  margin-bottom: 5px;
  font-size: 10px;
}

.prod-sec-div {
  text-align: right; 
}

.eachorder-heading-one,
.users-two-label {
  display: flex;
  align-items: center;
}

.eachorder-heading-one svg,
.users-two-label svg {
  margin-right: 5px;
}

.price-of-prod {
  color: gold;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
}

.order-price-total{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(203, 200, 200);
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 20px;
}

.order-price-date{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  margin: 10px;
}

.inside-order-price{
  font-size: 12px;
}

.payment-item{
/* border: 1px solid red; */
display: flex;
justify-content: space-between;
color: gray;
margin: 10px;
}

.pay-money{
  font-weight: 500;
}

/* ----------product modal css----------------------------------- */

/* Users.module.css */

.product-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
  /* border: 1px solid white; */
}

.product-modal-content {
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /* border: 1px solid white; */
}

.product-modal-img {
  max-width: 100%;
  height: 15rem;
  border-radius: 8px;
  /* margin-bottom: 15px; */
}

.main-pro-cont{
  /* border: 1px solid white; */
  background-color: white;
  padding: 10px;
  width: 300px;
  text-align: center;
  border-radius: 5px;
}

.product-modal-content-h2,
.product-modal-para {
  /* margin: 5px 0; */
  font-size: 16px; /* Adjust as needed */
  /* color: #333; */
  color: black;
  font-weight: 500;
  text-align: center;
  /* margin-left: 80px; */
}

.prod-close-mod{
  /* border: 1px solid white; */
  display: flex;
  justify-content: center;
}

.product-modal-btn {
  background: #007bff; 
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease; 
  margin-top: 20px;
}

.product-modal-btn:hover {
  background: #0056b3; 
}

.textarea-no-resize {
  resize: none;
}



.custom-modal-password{
  display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    z-index: 1;
    /* border: 1px solid red; */
}



.pass-change-h2{
  /* border: 1px solid red; */
  text-align: center;
  color: black;
  margin: 20px;
}

.input-pass-div{
  /* border: 10px solid rgb(200, 229, 247); */
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  /* border: 2px solid red; */
  width: 600px;
}

.falock{
  /* border: 1px solid red; */
  padding-left: 25px;
  position: absolute;
  margin-top: 41.5px;
  color: gray;
}

.pass-change-input{
  width: 80%;
  padding: 10px 30px;
  /* margin-bottom: 15px; */
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.toggle-password-btn {
  position: absolute;
  /* top: 50%; */
  margin-top: 50px;
  margin-right: 60px;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: gray;
}

.user-pass-change-btn{
  /* border: 1px solid red !important; */
  background-color: #186BAE !important;
  color: white !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px !important;
  cursor: pointer;
  padding: 0px !important;
  width: fit-content;
  text-align: start;
}

.created-icons{
  /* border: 1px solid red; */
  margin-top: 18px;
  margin-right: 30px;
  position: absolute;
  font-size: 20px;
  color: gray;
  right: 0;
}

.input-create-user{
  /* border: 1px solid red; */
  /* text-indent: 20px; */
  /* margin-left: 15px; */
}

.textfield-icons-create{
  /* border: 1px solid red; */
  margin-top: 32px;
  position: absolute;
  font-size: 20px;
  right: 0;
  margin-right: 80px;
  color: gray;
}

.inpt-for-addsub{
  /* border: 1px solid red; */
  right: 0;
  text-align: end;
}

.form-last-btn {
  background-color: #03A9F4;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border: none;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s; /* Add transition for smooth effect */
  cursor: pointer;
}

.form-last-btn:hover {
  background-color: #0288d1; /* Change background color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow on hover */
}


.order-skel{
  /* border: 1px solid red; */
  margin: 5px;
}

/* Add this to your CSS file or style tag */
.subscription-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.subscription-card h3 {
  margin-bottom: 12px;
  color: #333;
}

.subscription-card div {
  display: flex;
  justify-content: space-between;
}

.subscription-card p {
  margin: 8px 0;
  font-size: 14px;
  color: #555;
}

.due-date-div{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.status-renew{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.active-switch {
  border: 2px solid #2693e6;
  box-shadow: 0 0 10px #2693e6;
}

.inactive-switch {
  border: 2px solid #86d3ff; 
}
/* .subscription-active-button {
  background-color: green;
  color: white;
  height: 30px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
}

.subscription-inactive-button {
  background-color: red;
  color: white;
  height: 30px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
} */

/* Add this to your Users.module.css or create a new CSS file */

/* Pagination styles */
.pagination-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  /* border: 1px solid red; */
}

.pagination-btn {
  cursor: pointer;
  margin: 0 5px;
  padding: 4px 15px;
  border: none;
  border-radius: 5px;
  /* background-color: #3498db; */
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.page {
  cursor: pointer;
  margin: 0 5px;
  padding: 4px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f4f4f4;
}

.activePage {
  cursor: default;
  margin: 0 5px;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #3498db;
  color: #fff;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use 100% of the viewport height */
  width: 100vw; /* Use 100% of the viewport width */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999; /* Set a higher z-index to appear above other elements */
}

/* Add this CSS to your stylesheet or component */

.mark-icon {
  font-size: 80px !important;
  color: #a0a33a; /* Adjust the color as needed */
}

.renew-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.renew-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
  /* border: 2px solid red; */
}

.renew-modal-content h1 {
  margin-bottom: 10px;
}

.renew-modal-content h4 {
  margin-bottom: 10px;
  color: #333; /* Adjust the color as needed */
}

.renew-modal-content p {
  margin-bottom: 20px;
  color: #555; /* Adjust the color as needed */
}

.renew-button-confirm,
.renew-button-cancel {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.renew-button-confirm {
  background-color: #4caf50;
  color: white;
}

.renew-button-confirm:hover {
  background-color: #45a049;
}

.renew-button-cancel {
  background-color: #f44336;
  color: white;
}

.renew-button-cancel:hover {
  background-color: #d32f2f;
}

.form-group{
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ticket-btn{
  /* border: 1px solid yellow; */
  padding: 5px;
  /* margin: 10px; */
  width: 80px;
  border: none;
  border-radius: 5px;
}

/* Add these styles to your UserClasses CSS file */

/* Styles for disabled button */
.disabled-button {
  background-color: #cccccc;
  color: #666666;
  /* cursor: not-allowed; */
  opacity: 0.7;
}

.disabled-button:hover {
  background-color: #cccccc;
}

.status-renew .disabled-button {
  background-color: #cccccc; 
  color: #666666; 
  opacity: 0.7; 
}

.status-renew .disabled-button:hover {
  background-color: #cccccc;
}

.styled-date {
  /* font-size: 14px; */
  /* font-weight: bold; */
  /* color: #333; */
  /* width: max-content; */
  /* Add more styles as needed */
}

.pagination-container{
  /* border: 1px solid red; */
  padding: 10px;
}

/* Add these styles to your stylesheet */

.remark-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background for overlay effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

.remark-box-content {
  background: #fff; /* White background for the modal */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.remark-box textarea {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  border: 1px solid green;
  margin: 10px;
}

.remark-box button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remark-box-confirm {
  background-color: #4caf50; /* Green */
  color: white;
}

.remark-box-cancel {
  background-color: #f44336; /* Red */
  color: white;
}

.remark-box-content h2{
  /* border: 1px solid red; */
  border-bottom: 1px solid rgb(201, 200, 200);
}

.remark-box-content h4{
  width: 86%;
  /* padding: 10px; */
  /* margin-bottom: 10px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  /* resize: vertical; */
  /* border: 1px solid green; */
  /* margin: 10px; */
  margin: 0 auto;
  text-align: left;
  font-size: 12px;
  padding: 5px;
}

.user-orderby{
  /* border: 1px solid red; */
  margin-bottom: 10px;
}

.user-orderfrom{
  /* border: 1px solid green; */
  margin-bottom: 10px;
}

.error-message-password{
  color: red;
  text-align: center;
  padding: 5px;
}

.order-info-main{
  border-bottom: 1px solid rgb(218, 217, 217);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
}

.add-users-btn-new{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 20px;
}

.tag-last{
  position: relative;
}

.search-users-btn input[type="text"]::placeholder {
  /* Your placeholder text styles here */
  /* For example: */
  color: #999; /* Placeholder text color */
  /* font-style: italic; */
  font-size: small;
}

.close-mui-modal-btn{
  border: 1px solid red;
}