/* TicketDetails.module.css */

/* TicketDetails.module.css */

.main-data-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .users-main {
    width: 80%;
  }
  
  .add-users-btn {
    margin-bottom: 20px;
  }
  
  .ticket-users-main {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid red; */
    margin: 20px;
  }
  
  .ticket-users-div {
    display: flex;
    justify-content: center;
    /* border: 1px solid blue; */
  }
  
  .users-div {
    width: 100%;
  }
  
  .users-heading-one {
    color: #333;
  }
  
  .descript-ticket {
    margin-top: 10px;
    color: #555;
  }
  
  .ticket-description {
    width: 100%;
    height: 100px;
    /* padding: 10px; */
    margin-top: 5px;
    /* margin-left: 10px; */
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: none;
  }
  
  .image-ticket-main {
    margin-top: 20px;
    border: 1px solid rgb(201, 201, 201);
    width: 400px;
  }
  
  .image-ticket-sub {
    max-width: 100%;
    /* height: auto; */
    /* border: 2px solid #fff; */
    border-radius: 4px;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
  }

  .image-ticket-sub img{
    border: 4px solid rgb(220, 217, 217);
    height: 15rem;
    width: 100%;
  }
  
  .resolve-btn-main {
    margin-top: 20px;
    /* border: 1px solid red; */
    text-align: center;
  }
  
  .btn-resolve {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-resolve:hover {
    background-color: #45a049;
  }
  
  @media screen and (max-width: 600px){
    .image-ticket-sub{
      display: flex;
      flex-direction: column;
      width: auto;
    }
    .image-ticket-sub img{
      width: 100%;
      margin-top: 5px;
    }
    .image-ticket-main{
      width: auto;
    }
  }

  .ticket-textarea-div{
    /* border: 1px solid red; */
  }