/* Assuming you have TeamClasses['team-form'], TeamClasses['team-sub-form'], TeamClasses['team-label'], and TeamClasses['team-input'] defined */

.team-form {
    /* max-width: 400px; */
    margin: auto;
  }
  
  .team-sub-form {
    margin-bottom: 15px;
  }
  
  .team-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Change color according to your design */
  }
  
  .team-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
  }
  
  .team-input:focus {
    outline: none;
    border-color: #007bff; /* Change color according to your design */
  }
  
  /* Optional: Add styling for your submit button or any additional elements */
  
  /* Assuming you have TeamClasses['password-input-container'] and TeamClasses['eye-icon'] defined */

.password-input-container {
    position: relative;
  }
  
  .eye-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
.team-mem-drop{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}  

.team-drop{
  border: 1px solid #EBF2FA;
  margin: 30px;
  cursor: pointer;
  width: fit-content;
}

.team-drop-opt{
    border: 1px solid rgb(212, 211, 211);
    padding: 10px;
    width: 150px;
}

.upd-del-block{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.roles-upd-btn{
    /* border: 1px solid green; */
    background-color: #061739;
    border: none;
    color: white;
    padding: 10px;
    margin: 10px;
}

.team-del-btn{
    /* border: 1px solid green; */
    background-color: red;
    border: none;
    color: white;
    padding: 10px;
    margin: 10px;
}

/* .roles-upd-btn:hover{
    background-color: white;
    color: gray;
}

.team-del-btn:hover{
    background-color: white;
    color: red;
} */

/* --------------css for Admin settings-------------------- */

.change-password-btn{
    border: none;
    background-color: white;
    color: blue;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 10px;
}

.change-password-btn:hover{
    border-bottom: 1px solid rgb(215, 212, 212);
}

/* ------ */
  .input-admin-container {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  }
  
  .admin-input-label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .admin-input-field {
    margin-bottom: 1rem;
  width: 100%;
  }

  .admin-input-field input{
    padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  }

  .admin-input-field label{
    display: block;
  margin-bottom: 0.5rem;
  /* font-weight: bold; */
  }
  
  .admin-input-field:focus {
    border-color: #3f51b5;
  }
  
  .admin-password-toggler {
    margin-left: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #3f51b5;
  margin-bottom: 10px;
  }
  
  .admin-password-toggler:hover {
    color: #303f9f;
  }
  
  .admin-password-toggler svg {
    margin-left: 5px;
  }

  .admin-pass-btn{
    padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #3f51b5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }

  /* -------change pen css---------- */

  .mainDiv {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    font-family: 'Open Sans', sans-serif;
    /* border: 1px solid black; */
  }

 .admin-arrowback{
  color: #507fb1;
 }

 .admin-arrow-btn{
  border: none;
  background-color: white;
  margin: 10px;
  cursor: pointer;
 }

 .cardStyle {
    width: 500px;
    border-color: white;
    background: #fff;
    padding: 36px 0;
    border-radius: 4px;
    margin: 30px 0;
    box-shadow: 0px 0 2px 0 rgba(0,0,0,0.25);
  }
.signupLogo {
  max-height: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.formTitle{
  font-weight: 600;
  margin-top: 20px;
  color: #2F2D3B;
  text-align: center;
}
.inputLabel {
  font-size: 12px;
  color: #555;
  margin-bottom: 6px;
  margin-top: 24px;
}
  .inputDiv {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
input {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  border: solid 1px #ccc;
  padding: 0 11px;
}
input:disabled {
  cursor: not-allowed;
  border: solid 1px #eee;
}
.buttonWrapper {
  margin-top: 40px;
}
  .submitButton {
    width: 70%;
    height: 40px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #065492;
    border-color: #065492;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
.submitButton:disabled,
button[disabled] {
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
}

.loader {
  position: absolute;
  z-index: 1;
  margin: -2px 0 0 10px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear;
  animation: spin 2s linear;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message{
  color: red;
}

.errorText{
  color: red;
}